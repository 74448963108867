import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { navigate } from 'gatsby';

import api from '../../../services/api';
import { BASE } from '../../../utils/environament';
import semimagem from '../../../images/semimagem.png';

import * as S from './styles';

const url = `${BASE.API}/storage/`;

export default function Event() {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    async function load() {
      const posts = await api.get(`posts?type=post`);

      setPosts(posts.data.data);
    }

    load();
  }, []);

  return (
    <S.Container>
      {posts?.map(item => (
        <S.Card key={item.id}>
          {item.image ? (
            <img src={`${url}${item?.image?.file}`} alt="adotapet" />
          ) : (
            <img src={semimagem} alt="adotapet" />
          )}

          <S.Title>{item?.title}</S.Title>
          <S.Description>{item?.body}</S.Description>

          <Button
            variant="contained"
            color="default"
            onClick={() => navigate(`/eventos?id=${item.id}`)}
          >
            Veja mais
          </Button>
        </S.Card>
      ))}
    </S.Container>
  );
}
