import styled from 'styled-components';

export const ContainerPage = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin-top: 12px;

  img {
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 650px;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 14px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media only screen and (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;

  img {
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 250px;
    object-fit: cover;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  padding: 8px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 4px;
`;

export const Description = styled.div`
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
`;
