import React, { useEffect, useState } from 'react';

import api from '../../../services/api';
import { BASE } from '../../../utils/environament';

import * as S from './styles';

const url = `${BASE.API}/storage/`;

export default function EventDetail({ id }) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function load() {
      const post = await api.get(`posts/${id}`);

      setPost(post.data.data);
    }

    load();
  }, [id]);

  return (
    <S.ContainerPage>
      {post?.image && <img src={`${url}${post.image.file}`} alt="adotapet" />}

      <S.Title>{post?.title}</S.Title>

      <S.Description>{post?.body}</S.Description>
    </S.ContainerPage>
  );
}
