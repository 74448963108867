import React, { useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import Header from '../components/Header';
import Events from '../components/pages/Eventos';
import Detail from '../components/pages/Eventos/detail';
import Footer from '../components/Footer';
import banner from '../images/banner.jpg';

import { store, persistor } from '../store';

import { View, Container, Banner } from '../components/pages/styles';

export default function Eventos() {
  const [id, setId] = useState(null);

  useEffect(() => {
    const url_string = window.location.href; // eslint-disable-line
    const url = new URL(url_string);
    setId(url.searchParams.get('id'));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <SEO title="Eventos" />
          <Header />
          <View>
            {!id && (
              <Banner>
                <img src={banner} alt="adote um cão" />
              </Banner>
            )}
            <Container>{id ? <Detail id={id} /> : <Events />}</Container>
          </View>

          <ToastContainer autoClose={3000} />

          <Footer />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
